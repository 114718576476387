<template>
  <div>
    <contract-list-add-new
      :is-add-new-contract-sidebar-active.sync="isAddNewContractSidebarActive"
      :project-options="projectOptions"
      :customer-options="customerOptions"
      :team-options="teamOptions"
    />

    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <!-- Search -->
          <b-col cols="12" md="12">
            <div
              v-bind:class="{
                'align-items-center justify-content-end': isActive,
                'd-flex align-items-center justify-content-end': !isActive,
              }"
            >
              <b-input-group>
                <b-form-input
                  v-model="searchQuery"
                  @keyup.enter="searchWord()"
                  :placeholder="$t('message.bookmark.search')"
                />
                <b-input-group-append>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    class="btn-icon"
                    @click="searchWord()"
                  >
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>

              <div v-if="restrictions('button_chart_contracts')">
                <div class="ml-1">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    class="btn-icon"
                    :to="{ name: 'apps-chart-contracts-to-receive' }"
                  >
                    <feather-icon icon="PieChartIcon" />
                  </b-button>
                </div>
              </div>

              <div>
                <div class="ml-1">
                  <b-dropdown
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                  >
                    <template slot="button-content">
                      <feather-icon icon="FilterIcon" />
                    </template>
                    <b-dropdown-item
                      v-for="(customers, idx) in customerList"
                      :key="idx"
                      @click="customerFilter(customers.value)"
                    >
                      {{ customers.label }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>

              <br v-if="isActive" />
              <b-dropdown
                right
                variant="gradient-warning"
                class="ml-1 mr-1"
                v-if="restrictions('button_export_data')"
              >
                <template slot="button-content">
                  <feather-icon icon="DownloadIcon" />
                </template>
                <b-dropdown-item
                  ><download-excel
                    :data="contractList"
                    :before-generate="startDownload"
                    :before-finish="finishDownload"
                    :name="$t('message.buttons.dataInView')"
                  >
                    {{ $t("message.buttons.dataInView") }}
                  </download-excel></b-dropdown-item
                >
                <b-dropdown-item
                  ><download-excel
                    :fetch="allData"
                    :before-generate="startDownload"
                    :before-finish="finishDownload"
                    :name="$t('message.buttons.allData')"
                  >
                    {{ $t("message.buttons.allData") }}
                  </download-excel></b-dropdown-item
                >
              </b-dropdown>

              <b-button
                variant="primary"
                @click="isAddNewContractSidebarActive = true"
                v-if="restrictions('button_new_contract')"
              >
                <span class="text-nowrap">{{
                  $t("message.buttons.newContract")
                }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        id="my-table"
        ref="refcontractListTable"
        class="position-relative"
        :items="contractList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :busy="isBusy"
        show-empty
        @row-clicked="openViewPage"
        hover
      >
        <template #table-busy>
          <div class="text-center my-17">
            <b-spinner class="align-middle"></b-spinner>
            <strong> {{ $t("message.load") }}...</strong>
          </div>
        </template>

        <template slot="empty">
          <div class="text-center my-5">
            {{ $t("message.registerNotFound") }}
          </div>
        </template>

        <!-- Column: Date -->
        <template #cell(Data)="data">
          {{ localeDate(data.item.date) }}
        </template>

        <!-- Column: Name -->
        <template #cell(Nome)="data">
          {{ data.item.name }}
        </template>

        <!-- Column: Description -->
        <template #cell(Descrição)="data">
          <span v-html="data.item.description"></span>
        </template>

        <template #cell(Valor)="data">
          <a v-if="data.item.international">
            {{
              $n(
                (
                  parseFloat(data.item.value) *
                  parseFloat(data.item.internationalPrice)
                ).toString(),
                "currency"
              )
            }}
          </a>
          <a v-else>
            {{ $n(data.item.value, "currency") }}
          </a>
        </template>

        <!-- Column: Installments -->
        <template #cell(Parcelas)="data">
          {{ data.item.installments }}
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              {{ $t("message.pagelength") }} {{ dataMeta.from }}
              {{ $t("message.of") }} {{ dataMeta.to }} {{ $t("message.of") }}
              {{ totalRegisters }} {{ $t("register") }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            v-if="showPagination"
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRegisters"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              aria-controls="my-table"
              @input="eventPage(currentPage)"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import usecontractsList from "./useContractsList";
import ContractListAddNew from "./ContractListAddNew.vue";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import moment from "moment";
import Ripple from "vue-ripple-directive";
import JsonExcel from "vue-json-excel";
import Vue from "vue";
import AccessControl from "@core/utils/access-control";
import ListWidth from "@core/utils/listWidth";
import store from "@/store";

Vue.component("downloadExcel", JsonExcel);

export default {
  components: {
    ContractListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BSpinner,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      contractList: [],
      totalRegisters: 0,
      perPage: 0,
      currentPage: 1,
      isBusy: false,
      showPagination: true,
      allDataSize: 9999,

      //Select
      customerOptions: [],
      projectOptions: [],
      teamOptions: [],
      customerList: [],

      //Param
      paramData: store.state.user.paramData,

      userData: store.state.user.userData,

      //Resize
      windowWidth: window.innerWidth,
      isActive: true,
    };
  },

  computed: {
    tableColumns() {
      const lang = this.$i18n.locale;
      if (!lang) {
        return [];
      }
      return [
        { key: "Data", sortable: false, label: this.getColLabel("date") },
        { key: "Nome", sortable: false, label: this.getColLabel("name") },
        {
          key: "Descrição",
          sortable: false,
          label: this.getColLabel("description"),
        },
        { key: "Valor", sortable: false, label: this.getColLabel("value") },
        {
          key: "Parcelas",
          sortable: false,
          label: this.getColLabel("installments"),
        },
      ];
    },
  },

  async created() {
    this.onResize();
    this.getContractList();

    var teamList = [];
    var teamId = [];
    await axios
      .get(`${URL_API}team/select_list`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].name;
          const id = response.data[i].id;
          teamList.push(element);
          teamId.push(id);
          this.teamOptions.push({ label: teamList[i], value: teamId[i] });
        }
      });

    var projectList = [];
    var projectId = [];
    await axios
      .get(`${URL_API}project/select_list`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].name;
          const id = response.data[i].id;
          projectList.push(element);
          projectId.push(id);
          this.projectOptions.push({
            label: projectList[i],
            value: projectId[i],
          });
        }
      });

    var customerList = [];
    var customerId = [];
    await axios
      .get(`${URL_API}customer/select_list`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].name;
          const id = response.data[i].id;
          customerList.push(element);
          customerId.push(id);
          this.customerOptions.push({
            label: customerList[i],
            value: customerId[i],
          });
          this.customerList.push({
            label: customerList[i],
            value: customerId[i],
          });
        }
      });

    this.customerList.push({
      label: this.$i18n.t("message.projects.all"),
      value: "All",
    });
  },

  mounted() {
    this.$root.$on("newListContract", (newListContract) => {
      this.isBusy = true;
      axios
        .get(
          `${URL_API}contract/list-dto?page=0&sort=id,${this.paramData.listSort}&size=${this.paramData.tableSize}`,
          {
            headers: getHeader(this.userData),
          }
        )
        .then((response) => {
          this.contractList = response.data.content;
          this.totalRegisters = response.data.totalElements;
          this.perPage = response.data.numberOfElements;
          this.currentPage = 1;
          this.isBusy = false;
        });
    });

    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    async allData() {
      const response = await axios.get(
        `${URL_API}contract/list?page=0&sort=id,${this.paramData.listSort}&size=${this.allDataSize}`,
        { headers: getHeader(this.userData) }
      );
      return response.data.content;
    },

    async customerFilter(customerId) {
      if (customerId === "All") {
        this.getContractList();
      } else {
        var allData = [];
        this.isBusy = true;
        allData = await this.allData();
        this.isBusy = false;
        this.contractList = [];

        for (var i = 0; i < allData.length; i++) {
          if (allData[i].customerId === customerId) {
            this.contractList.push(allData[i]);
          }
        }
      }
    },

    onResize() {
      this.windowWidth = window.innerWidth;
      this.isActive = ListWidth(this.windowWidth);
    },

    getColLabel(colName) {
      return this.$i18n.t("message.list." + colName);
    },

    openViewPage(row) {
      row._showDetails = !row._showDetails;
      this.$router.push({ name: "apps-contract-view", params: { id: row.id } });
    },

    restrictions(value) {
      return AccessControl(value);
    },

    startDownload() {
      this.isBusy = true;
    },

    finishDownload() {
      this.isBusy = false;
    },

    async getContractList() {
      this.isBusy = true;
      await axios
        .get(
          `${URL_API}contract/list-dto?page=0&sort=id,${this.paramData.listSort}&size=${this.paramData.tableSize}`,
          {
            headers: getHeader(this.userData),
          }
        )
        .then((response) => {
          this.totalRegisters = response.data.totalElements;
          this.contractList = response.data.content;
          this.perPage = response.data.numberOfElements;
          this.isBusy = false;
        });

      if (this.contractList === null) {
        this.showPagination = false;
      } else {
        this.showPagination = true;
        this.currentPage = 1;
      }
    },

    async eventPage(currentPage) {
      this.isBusy = true;
      await axios
        .get(
          `${URL_API}contract/list-dto?page=${currentPage - 1}&sort=id,${
            this.paramData.listSort
          }&size=${this.paramData.tableSize}`,
          { headers: getHeader(this.userData) }
        )
        .then((response) => {
          this.contractList = response.data.content;
          this.isBusy = false;
        });

      if (this.contractList === null) {
        this.showPagination = false;
      } else {
        this.showPagination = true;
      }
    },

    async getContractFilter() {
      this.isBusy = true;
      await axios
        .get(`${URL_API}contract/search?searchTerm=${this.searchQuery}`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          this.contractList = [];
          if (response.data != []) {
            for (var i = 0; i < response.data.length; i++) {
              this.contractList.push(response.data[i]);
            }
          }
          this.isBusy = false;
          this.showPagination = false;
        });
    },

    searchWord() {
      if (this.searchQuery) {
        this.getContractFilter();
      } else {
        this.getContractList();
      }
    },

    localeDate(value) {
      if (value) {
        return this.$i18n.d(
          new Date(value.split("-")),
          "short",
          this.$i18n.locale
        );
      }
      return this.$i18n.t("message." + "noDate");
    },
  },

  setup() {
    const isAddNewContractSidebarActive = ref(false);

    const {
      perPage,
      currentPage,
      totalcontracts,
      dataMeta,
      searchQuery,
      refcontractListTable,
    } = usecontractsList();

    return {
      isAddNewContractSidebarActive,
      perPage,
      currentPage,
      totalcontracts,
      dataMeta,
      searchQuery,
      refcontractListTable,
      avatarText,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.my-17 {
  margin-top: 17.2rem;
  margin-bottom: 17.2rem;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
