<template>
  <b-sidebar
    id="add-new-contract-sidebar"
    :visible="isAddNewContractSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-contract-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ $t("message.contracts.createNewContract") }}</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(submitNewcontract())"
          @reset.prevent="resetForm"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="nome"
            rules="required"
          >
            <b-form-group
              label-for="name"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.tableHeader.name") }}
              </template>
              <b-form-input
                id="name"
                v-model="contractData.name"
                :state="getValidationState(validationContext)"
              />
            </b-form-group>
          </validation-provider>

          <!-- Description -->
          <validation-provider
            #default="validationContext"
            name="descrição"
            rules="required"
          >
            <b-form-group
              label-for="description"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.projects.description") }}
              </template>
              <quill-editor
                id="title"
                style="margin-bottom: 82px; height: 300px"
                :options="editorOption"
                v-model="contractData.description"
              />
            </b-form-group>
          </validation-provider>

          <!-- Date -->
          <validation-provider
            #default="validationContext"
            name="data"
            rules="required"
          >
            <b-form-group label-for="startDate">
              <template v-slot:label>
                {{ $t("message.tableHeader.date") }}
              </template>
              <flat-pickr
                v-model="contractData.date"
                class="form-control"
                :config="flatConfig"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ $t("message.list.fieldRequired") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Customer Id -->
          <validation-provider
            #default="validationContext"
            name="cliente"
            rules="required"
          >
            <b-form-group
              label-for="customer"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.projects.customer") }}
              </template>
              <v-select
                v-model="contractData.customerId"
                :options="customerOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="contract-role"
              />
            </b-form-group>
          </validation-provider>

          <!-- Create project -->
          <b-form-group label-for="deadlineTasks">
            <template v-slot:label>
              {{ $t("message.expenses.createProject") }}
            </template>
            <b-form-checkbox
              v-model="createProject"
              name="is-vertical-menu-collapsed"
            />
          </b-form-group>

          <!-- Projects -->

          <b-form-group v-if="createProject != true" label-for="projects">
            <template v-slot:label>
              {{ $t("message.projects.projects") }}
            </template>
            <v-select
              multiple
              v-model="contractData.projectsId"
              :options="projectOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="contract-role"
            />
          </b-form-group>

          <!-- Description -->

          <b-form-group v-if="createProject === true" label-for="description">
            <template v-slot:label>
              {{ $t("message.projects.description") }}
            </template>
            <quill-editor
              id="title"
              style="margin-bottom: 82px; height: 300px"
              :options="editorOption"
              v-model="projectData.description"
            />
          </b-form-group>
          <!-- Start Date -->

          <b-form-group label-for="startDate" v-if="createProject === true">
            <template v-slot:label>
              {{ $t("message.date.start") }}
            </template>
            <flat-pickr
              v-model="projectData.startDate"
              class="form-control"
              :config="flatConfig"
            />
          </b-form-group>

          <!-- End Date -->
          <b-form-group label-for="endDate" v-if="createProject === true">
            <template v-slot:label>
              {{ $t("message.date.end") }}
            </template>
            <flat-pickr
              v-model="projectData.endDate"
              class="form-control"
              :config="flatConfig"
            />
          </b-form-group>

          <!-- Estimated Time -->

          <b-form-group label-for="estimatedTime" v-if="createProject === true">
            <template v-slot:label>
              {{ $t("message.projects.estimatedTime") }}
            </template>
            <number-input
              v-model="projectDataEstimatedTime"
              center
              controls
              :min="0"
            />
          </b-form-group>

          <!-- Team -->
          <b-form-group label-for="teams" v-if="createProject === true">
            <template v-slot:label>
              {{ $t("message.team.team") }}
            </template>
            <v-select
              multiple
              v-model="projectData.teams"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="teamOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="team-role"
            />
          </b-form-group>

          <!-- Total Value -->
          <validation-provider
            #default="validationContext"
            name="valor"
            rules="required"
          >
            <b-form-group
              label-for="value"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.expenses.value") }}
              </template>
              <b-form-input
                id="value"
                v-model="contractData.value"
                v-money="getCurrencyFormat()"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- International -->
          <b-form-group label-for="deadlineTasks">
            <template v-slot:label>
              {{ $t("message.expenses.international") }}
            </template>
            <b-form-checkbox
              v-model="internationalContract"
              name="is-vertical-menu-collapsed"
            />
          </b-form-group>

          <!-- International Value -->

          <b-form-group label-for="value" v-if="internationalContract">
            <template v-slot:label>
              {{ $t("message.expenses.price") }}
            </template>
            <b-form-input
              id="value"
              v-model="contractData.internationalPrice"
              v-money="getCurrencyFormat()"
            />
          </b-form-group>

          <br />

          <div class="form-group" v-for="(input, k) in installments" :key="k">
            <b-row>
              <b-col md="4" class="action">
                <b>{{ k + 1 }}ª  </b> {{ $t("message.contracts.parcel") }}
                {{ $t("message.of") }}
              </b-col>
              <b-col md="4">
                <b-input-group append="%">
                  <b-form-input placeholder="10" v-model="input.value" />
                </b-input-group>
              </b-col>

              <b-col md="2">
                <b-button
                  v-show="k == installments.length - 1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon"
                  @click="addInstallment(k)"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
              </b-col>
              <b-col>
                <b-button
                  md="2"
                  v-show="k || (!k && installments.length > 1)"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  class="btn-icon"
                  @click="removeInstallment(k)"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-col>
            </b-row>
          </div>

          <br />
          <hr class="mt-2 mb-3" />

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              v-if="!disabledButton"
            >
              {{ $t("message.buttons.create") }}
            </b-button>

            <b-button
              v-else
              disabled
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
            >
              {{ $t("message.buttons.create") }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("message.buttons.cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable */
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormSpinbutton,
  BFormTextarea,
  BFormCheckbox,
  BRow,
  BCol,
  BInputGroup,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import getHeader from "@core/utils/auth";
import axios from "@axios";
import URL_API from "@core/utils/env";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import { VMoney } from "v-money";
import currency from "@/@core/utils/currency";
import date from "@/@core/utils/date";
import dateInput from "@/@core/utils/dateInput";
import Vue from "vue";
import VueNumberInput from "@chenfengyuan/vue-number-input";
Vue.use(VueNumberInput);
import store from "@/store";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    flatPickr,
    BFormSpinbutton,
    BFormTextarea,
    BFormCheckbox,
    BRow,
    BCol,
    BInputGroup,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  directives: {
    Ripple,
    money: VMoney,
  },
  model: {
    prop: "isAddNewContractSidebarActive",
    event: "update:is-add-new-contract-sidebar-active",
  },
  props: {
    isAddNewContractSidebarActive: {
      type: Boolean,
      required: true,
    },
    projectOptions: {
      type: Array,
      required: true,
    },
    customerOptions: {
      type: Array,
      required: true,
    },
    teamOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      flatConfig: {
        dateFormat: "d/m/Y",
      },
      createProject: false,

      projectData: {},

      projectDataEstimatedTime: 0,

      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },

      installments: [
        {
          value: "",
        },
      ],

      //Param
      paramData: store.state.user.paramData,

      userData: store.state.user.userData,

      disabledButton: false,

      internationalContract: false,
    };
  },

  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.flatConfig = dateInput(newVal);
    },
    "_props.isAddNewContractSidebarActive": function (newVal, oldVal) {
      if (newVal) {
        this.projectDataEstimatedTime = 0;
      }
    },
  },

  mounted() {
    if (this.$i18n.locale) {
      this.flatConfig = dateInput(this.$i18n.locale);
    }
  },

  methods: {
    getCurrencyFormat() {
      return currency(this.$i18n.locale);
    },

    async saveProject() {
      this.projectData.estimatedTime = this.projectDataEstimatedTime;
      this.projectData.name = this.contractData.name;
      this.projectData.status = "_PENDING";

      this.projectData.startDate = date(
        this.$i18n.locale,
        this.projectData.startDate
      );
      this.projectData.endDate = date(
        this.$i18n.locale,
        this.projectData.endDate
      );

      var list = [];

      axios({
        method: "post",
        url: `${URL_API}project`,
        headers: getHeader(this.userData),
        data: this.projectData,
      })
        .then((response) => {
          list.push(response.data.id);
          this.contractData.projectsId = list;
        })
        .then(() => {
          this.saveContract();
        });
    },

    async saveContract() {
      this.disabledButton = true;
      this.onSubmit();

      this.contractData.date = date(this.$i18n.locale, this.contractData.date);

      if (this.$i18n.locale === "en") {
        this.contractData.value = Number(
          this.contractData.value.replace(/[^0-9\.-]+/g, "")
        ).toFixed(2);
        this.contractData.internationalPrice = Number(
          this.contractData.internationalPrice.replace(/[^0-9\.-]+/g, "")
        ).toFixed(2);
      } else {
        this.contractData.value = this.switchDotsAndCommas(
          this.contractData.value
        );
        this.contractData.value = Number(
          this.contractData.value.replace(/[^0-9\.-]+/g, "")
        ).toFixed(2);

        this.contractData.internationalPrice = this.switchDotsAndCommas(
          this.contractData.internationalPrice
        );
        this.contractData.internationalPrice = Number(
          this.contractData.internationalPrice.replace(/[^0-9\.-]+/g, "")
        ).toFixed(2);
      }

      axios({
        method: "post",
        url: `${URL_API}contract`,
        headers: getHeader(this.userData),
        data: this.contractData,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("message.toast.create"),
              icon: "CheckIcon",
              variant: "success",
              text: `Contrato criado com sucesso`,
            },
          });
        })
        .then(() => {
          this.$root.$emit("newListContract", null);
          this.disabledButton = false;
        })
        .catch((error) => {
          this.disabledButton = false;
          console.log(error);
        });
    },

    submitNewcontract() {
      this.$refs.refFormObserver.validate().then((success) => {
        if (success) {
          this.contractData.international = this.internationalContract;

          if (this.contractData.installments === undefined) {
            this.contractData.installments = 1;
          }

          var list = [];
          for (var i = 0; i < this.installments.length; i++) {
            list.push(this.installments[i].value);
          }

          this.contractData.installmentList = list;
          this.contractData.status = true;

          if (this.$i18n.locale === "en") {
            this.contractData.value = Number(
              this.contractData.value.replace(/[^0-9\.-]+/g, "")
            ).toFixed(2);
            this.contractData.internationalPrice = Number(
              this.contractData.internationalPrice.replace(/[^0-9\.-]+/g, "")
            ).toFixed(2);
          } else {
            this.contractData.value = this.switchDotsAndCommas(
              this.contractData.value
            );
            this.contractData.value = Number(
              this.contractData.value.replace(/[^0-9\.-]+/g, "")
            ).toFixed(2);

            this.contractData.internationalPrice = this.switchDotsAndCommas(
              this.contractData.internationalPrice
            );
            this.contractData.internationalPrice = Number(
              this.contractData.internationalPrice.replace(/[^0-9\.-]+/g, "")
            ).toFixed(2);
          }

          this.contractData.date = date(
            this.$i18n.locale,
            this.contractData.date
          );

          if (this.createProject != false) {
            this.saveProject();
          } else {
            axios({
              method: "post",
              url: `${URL_API}contract`,
              headers: getHeader(this.userData),
              data: this.contractData,
            })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: this.$i18n.t("message.toast.create"),
                    icon: "CheckIcon",
                    variant: "success",
                    text: `Contrato criado com sucesso`,
                  },
                });
              })
              .then(() => {
                axios
                  .get(
                    `${URL_API}contract/list-dto?page=0&sort=id,${this.paramData.listSort}&size=${this.paramData.tableSize}`,
                    {
                      headers: getHeader(this.userData),
                    }
                  )
                  .then((response) => {
                    this.$root.$emit("newListContract", response.data);
                    this.onSubmit();
                  });
              });
          }
        }
      });
    },

    switchDotsAndCommas(s) {
      function switcher(match) {
        return match == "," ? "." : ",";
      }
      return s.replaceAll(/\.|\,/g, switcher);
    },

    addInstallment() {
      this.installments.push({ value: "" });

      if (this.contractData.installments === undefined) {
        this.contractData.installments = 2;
      } else {
        this.contractData.installments++;
      }
    },

    removeInstallment(index) {
      this.installments.splice(index, 1);
      this.contractData.installments--;
    },
  },

  setup(props, { emit }) {
    const blankcontractData = {};

    const contractData = ref(JSON.parse(JSON.stringify(blankcontractData)));
    const resetcontractData = () => {
      contractData.value = JSON.parse(JSON.stringify(blankcontractData));
    };

    const onSubmit = () => {
      emit("refetch-data");
      emit("update:is-add-new-contract-sidebar-active", false);
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetcontractData);

    return {
      contractData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-contract-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.action {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
